// src/pages/ProfilePage.js
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './ProfilePage.css';

const ProfilePage = () => {
  const [user, setUser] = useState(null);  // Состояние для пользователя
  const [purchases, setPurchases] = useState([]);  // Состояние для покупок

  useEffect(() => {
    fetch('/api/user', { credentials: 'include' })
      .then(res => res.json())
      .then(userData => {
        setUser(userData);
        return fetch(`/orders/user/${userData.id}`, { credentials: 'include' });
      })
      .then(res => res.json())
      .then(data => {
        if (data.orders) {
          setPurchases(data.orders);
        }
      })
      .catch(error => console.error('Error fetching user orders:', error));
  }, []);



  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Номер заказа скопирован в буфер обмена.');
      })
      .catch(err => {
        console.error('Ошибка при копировании:', err);
        alert('Не удалось скопировать номер заказа.');
      });
  };

  return (
    <div className="profile-page">
      <Header />
      <div className="user-purchase-content">
        {user ? (
          <>
            <div className="profile-info">
              <h1>User Profile</h1>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Email:</strong> {user.email}</p>
            </div>
            <div className="user-purchases">
              <h2>Мои покупки</h2>
              {purchases.length > 0 ? (
                <div className="purchases-container">
                  {purchases.map(purchase => (
                    <div key={purchase.id} className="purchase-card">
                      <div className="purchase-header">
                        <p><strong>Order ID:</strong> {purchase.id}</p>
                        <button className="copy-id-button" onClick={() => copyToClipboard(purchase.id)}></button>
                      </div>
                      <div className="purchase-content">
                        <p><strong>Товары:</strong></p>
                        <ul>
                          {purchase.items.map((item, index) => (
                            <li key={index}>
                              <strong>{item.name}</strong>
                              (x{item.quantity}) - {item.status}
                            </li>
                          ))}
                        </ul>
                        {purchase.coupon && (
                          <p>
                            <strong>Применен купон:</strong> {purchase.coupon.code} (-{purchase.coupon.discountValue}%)
                          </p>
                        )}
                        <p><strong>Всего:</strong> {purchase.total} {purchase.currency}</p>
                        <p><strong>Состояние покупки:</strong> {purchase.status}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No purchases yet.</p>
              )}
            </div>
          </>
        ) : (
          <p>Please log in to view your profile.</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePage;
